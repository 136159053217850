.footer {
    width: 100%;
    padding: 56px 0 24px;
}

.menu_items {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.menu_items a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #FFFFFF;
}

.whatsapp, .whatsapp p {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}


.whatsapp img {
    width: 30px;
    height: 30px;
}

.ending {
    margin-top: 36px;
    padding-top: 36px;
    border-top: 1px solid #FFF;
}

.sociais {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
    margin: 12px 0;
}